
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import Pagination from "@/comp/Pagination/index.vue";
import { savePropCat, propCatList, categoryGroupList } from "@/api/request/item";

//组件
@Component({
  name: "Category",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private categoryList: any[] = [];
  private listLoading: boolean = true;

  //请求参数
  private listQuery: any = {
    //页码数据
    page: 1,
    page_size: 20,

    //临时数据
    prop_id_str: "",

    //上传数据
    model: 0, //道具类型
    cat_id: 0, //分类ID
    prop_id: 0, //道具ID
    prop_name: "", //道具名称
  };

  //模块列表
  private modelList: any[] = [
    { model: 0, name: "全部道具类型" },
    { model: 4, name: "广场互动" },
    { model: 8, name: "礼物" },
  ];

  //归属列表
  private belongList: any[] = [
    { belong: 1, name: "礼物", list: [] },
    { belong: 2, name: "广场互动", list: [] },
    { belong: 3, name: "钻石商城", list: [] },
    { belong: 4, name: "互动币商城", list: [] },
  ];

  //创建
  async created() {
    //获取类别列表
    await this.getCategoryList();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.prop_id = Number(this.listQuery.prop_id_str);

    //获取数据
    const { data } = await propCatList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //获取分类列表
  private async getCategoryList() {
    //获取数据
    this.listLoading = true;

    //获取数据
    const { data } = await categoryGroupList({});

    //数据赋值
    this.categoryList = [];
    for (var id in data) {
      for (var i: number = 0; i < data[id].length; i++) {
        this.categoryList.push(data[id][i]);
      }
      for (var j: number = 0; j < this.belongList.length; j++) {
        if (Number(id) == this.belongList[j].belong) {
          this.belongList[j].list = data[id];
          break;
        }
      }
    }
    this.categoryList.unshift({ id: 0, name: "全部分类" });
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.addForm = {
      prop_id: row.id,
      cat_ids: row.cat_ids.length == 1 && row.cat_ids[0] == 0 ? [] : row.cat_ids,
    };
    this.categorySelect = [];
    for (var i: number = 0; i < this.belongList.length; i++) {
      if (row.belong == this.belongList[i].belong) {
        this.categorySelect = GFunc.deepClone(this.belongList[i].list);
        break;
      }
    }

    //显示界面
    this.dialogVisible = true;
  }

  //获取道具类型名称
  private getModelName(model: number): string {
    for (var i: number = 0; i < this.modelList.length; i++) {
      if (model == this.modelList[i].model) {
        return this.modelList[i].name;
      }
    }
    return "";
  }

  //获取分类归属名称
  private getBelongName(belong: number): string {
    for (var i: number = 0; i < this.belongList.length; i++) {
      if (belong == this.belongList[i].belong) {
        return this.belongList[i].name;
      }
    }
    return "";
  }

  //获取分类名称
  private getCategoryName(ids: Array<number>): string {
    //定义变量
    var str: string = "";
    if (ids.length == 1 && ids[0] == 0) {
      str = "";
    } else {
      for (var i: number = 0; i < ids.length; i++) {
        for (var j: number = 0; j < this.categoryList.length; j++) {
          if (ids[i] == this.categoryList[j].id) {
            str += this.categoryList[j].name + ",";
            break;
          }
        }
      }
      if (str.length > 0) str = str.substring(0, str.length - 1);
    }

    //返回分类名称
    return str;
  }

  //--------------------------------- 编辑 ---------------------------------
  //定义变量
  private categorySelect: any[] = [];
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;

  //添加表单
  private addForm: any = {
    prop_id: 0,
    cat_ids: [1, 2],
  };

  //更新消息
  private async updateData() {
    //数据赋值
    if (this.addForm.cat_ids.length == 0) this.addForm.cat_ids = [0];

    //保存任务
    await savePropCat(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //显示提示
    this.$notify({
      title: "成功",
      message: "编辑成功",
      type: "success",
      duration: 2000,
    });

    //获取列表
    this.getList();
  }
}
